import { authenticationService } from "./authentication.service";

import { handleResponse } from "../_helpers/handle-response";
import { userService } from "./user.service";
import moment from "moment";
import download from "downloadjs";
function getToken() {
  // return authorization header with jwt token
  //const currentUser = authenticationService.currentUserValue;
  if (
    authenticationService.currentDataValue !== undefined &&
    authenticationService.currentDataValue !== null &&
    authenticationService.currentDataValue.access_token !== undefined
  ) {
    return `Bearer ${authenticationService.currentDataValue.access_token}`;
  } else {
    return "";
  }
}

// const authApiUrl = 'https://leguard2-demo-auth.azurewebsites.net';
// const activityApiUrl = 'https://leguard2-demo-activity-api.azurewebsites.net'
// const mainApiUrl = 'https://leguard2-demo-main-api.azurewebsites.net';
// const reportApiUrl = 'https://leguard2-demo-report-api.azurewebsites.net';

const authApiUrl = process.env.REACT_APP_AUTH_API;
const activityApiUrl = process.env.REACT_APP_ACTIVITY_API;
const mainApiUrl = process.env.REACT_APP_MAIN_API;
const reportApiUrl = process.env.REACT_APP_REPORT_API;

// const authApiUrl = 'https://leguard2-prod-auth-api.azurewebsites.net';
// const activityApiUrl = 'https://leguard2-prod-activity-api.azurewebsites.net';
// const mainApiUrl = 'https://leguard2-prod-main-api.azurewebsites.net';
// const reportApiUrl = 'https://leguard2-prod-report-api.azurewebsites.net';

// const authApiUrl = "http://localhost/Leguard2.Auth";
// const activityApiUrl = "http://localhost/Leguard2.Activity.API";
// const mainApiUrl = "http://localhost/Leguard2.Main.API";
// const reportApiUrl = "http://localhost/Leguard2.Report.API";

// const authApiUrl = 'https://leguard2-test-auth-api.azurewebsites.net';
// const activityApiUrl = 'https://leguard2-test-activity-api.azurewebsites.net'
// const mainApiUrl = 'https://leguard2-test-main-api.azurewebsites.net';
// const reportApiUrl = 'https://leguard2-test-report-api.azurewebsites.net';

const handleError = (error) => {
  //alert('handleError');
  console.log(
    "----------------------------------ERROR----------------------------"
  );
  console.log(error);
  console.log(
    "----------------------------------ERROR----------------------------"
  );
};

const getRequestOptions = () => {
  return {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  };
};

const postRequestOptions = (data) => {
  return {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: getToken() },
    body: JSON.stringify(data),
  };
};

const putRequestOptions = (data) => {
  return {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: getToken() },
    body: JSON.stringify(data),
  };
};

const deleteRequestOptions = () => {
  return {
    method: "DELETE",
    headers: { "Content-Type": "application/json", Authorization: getToken() },
  };
};

function getItemListByEntityId(apiCategoryRouteName, entityId) {
  let url = `${mainApiUrl}/api/${apiCategoryRouteName}/GetList?entityId=${entityId}`;
  return fetch(url, getRequestOptions())
    .then(handleResponse)
    .then((msg) => {
      return msg;
    }, handleError);
}

function getEntityById(entityId) {
  return fetch(
    `${mainApiUrl}/api/Entity/GetEntity/${entityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getEntityTypeList() {
  return fetch(`${mainApiUrl}/api/Entity/GetEntityTypes`, getRequestOptions())
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createItem(apiCategoryRouteName, entityId, name) {
  let data = {
    name: name,
    entityId: entityId,
  };

  return fetch(
    `${mainApiUrl}/api/${apiCategoryRouteName}/Create${apiCategoryRouteName}`,
    postRequestOptions(data)
  )
    .then(handleResponse)
    .then((msg) => {
      return msg;
    }, handleError);
}

function updateItem(apiCategoryRouteName, item) {
  return fetch(
    `${mainApiUrl}/api/${apiCategoryRouteName}/Update${apiCategoryRouteName}`,
    putRequestOptions(item)
  )
    .then(handleResponse)
    .then((msg) => {
      return msg;
    }, handleError);
}

function getEntityListByEntityId(entityId) {
  return fetch(
    `${mainApiUrl}/api/Entity/GetListByEntity/${entityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getUserTypes() {
  return fetch(`${mainApiUrl}/api/User/GetUserTypes`, getRequestOptions())
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getUserListByUserId(userId, entityId, partnershipFilterValue = -1) {
  return fetch(
    `${mainApiUrl}/api/User/GetUsers?userId=${userId}&entityId=${entityId}&partnershipFilterValue=${partnershipFilterValue}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getUserListByUserIdIncludePartnership(userId, partnershipId) {
  return fetch(
    `${mainApiUrl}/api/User/GetUsersIncludePartnership?userId=${userId}&partnershipId=${partnershipId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getUserListByUserIdIncludePartnershipForMatter(
  userId,
  partnershipId,
  matterId
) {
  return fetch(
    `${mainApiUrl}/api/User/GetUsersIncludePartnershipForMatter?userId=${userId}&partnershipId=${partnershipId}&matterId=${matterId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getUserByUserId(userId) {
  return fetch(
    `${mainApiUrl}/api/User/GetUser?userId=${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getTimezoneList() {
  return fetch(`${mainApiUrl}/api/User/GetTimezones`, getRequestOptions())
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getCurrencyList() {
  return fetch(`${mainApiUrl}/api/User/GetCurrencies`, getRequestOptions())
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createUser(user) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/User/CreateUser?userId=${currentUser.userId}`,
    postRequestOptions(user)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getUserByEmail(email) {
  return fetch(
    `${mainApiUrl}/api/User/GetUserByEmail?email=${email}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateUser(user) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/User/UpdateUser?userId=${currentUser.userId}`,
    postRequestOptions(user)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createEntity(entity) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Entity/CreateEntity/${currentUser.userId}`,
    postRequestOptions(entity)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateEntity(entity) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Entity/UpdateEntity/${currentUser.userId}`,
    postRequestOptions(entity)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getCategoryPackageListByEntityId(entityId) {
  return fetch(
    `${mainApiUrl}/api/Package/GetListByEntity/${entityId}/true`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createPackage(categoryPackage) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Package/CreatePackage/${currentUser.userId}`,
    postRequestOptions(categoryPackage)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function updatePackage(categoryPackage) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Package/UpdatePackage/${currentUser.userId}`,
    postRequestOptions(categoryPackage)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getPartnershipListByEntityId(
  entityId,
  userId = 0,
  includeInactive = false
) {
  return fetch(
    `${mainApiUrl}/api/Partnership/GetListByEntity/${entityId}/${userId}?includeInactive=${includeInactive}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getPartnershipListByInvoiceReviewerId(invoiceReviewerId) {
  return fetch(
    `${mainApiUrl}/api/Partnership/GetListByInvoiceReviewerId/${invoiceReviewerId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getUserPartnershipDefaultRates(userId) {
  return fetch(
    `${mainApiUrl}/api/Partnership/GetUserPartnershipDefaultRates/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getPartnershipListByManager(entityId, userId) {
  return fetch(
    `${mainApiUrl}/api/Partnership/GetListByManager/${entityId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getPartnershipById(partnershipId) {
  return fetch(
    `${mainApiUrl}/api/Partnership/GetPartnership/${partnershipId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getUserListByPartnership(partnershipId, roleId) {
  return fetch(
    `${mainApiUrl}/api/Partnership/GetUserListByPartnership/${partnershipId}/${roleId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getAuthViewerListByPartnershipId(id) {
  return getUserListByPartnership(id, 1);
}
function getBillerListByPartnershipId(id) {
  return getUserListByPartnership(id, 2);
}
function getRespLawyerListByPartnershipId(id) {
  return getUserListByPartnership(id, 3);
}
function getPackageListByPartnershipId(partnershipId) {
  return fetch(
    `${mainApiUrl}/api/Package/GetListByPartnership/${partnershipId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function inviteEntity(entityModel) {
  let currentUser = userService.getLoggedInUser();

  return fetch(
    `${mainApiUrl}/api/Entity/InviteEntity/${currentUser.userId}`,
    postRequestOptions(entityModel)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getEntityListByRole(roleId, search) {
  return fetch(
    `${mainApiUrl}/api/Entity/GetListSearch/${roleId}?searchstring=${search}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getAllClientEntityList(search) {
  return getEntityListByRole(1, search);
}
function getAllLawfirmEntityList(search) {
  return getEntityListByRole(2, search);
}

function getDefaultEmailText(entityId) {
  return fetch(
    `${mainApiUrl}/api/Entity/GetDefaultEmailText?entityId=${entityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getAllClientEntityListByBillerEntityId(entityId) {
  return fetch(
    `${mainApiUrl}/api/Entity/GetListByBillerEntityId/${entityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function changeAlreadyRemindedFlagOnActivity(activityId) {
  return fetch(
    `${activityApiUrl}/api/Activity/ChangeAlreadyRemindedFlag/${activityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createPartnership(userId, partnership) {
  return fetch(
    `${mainApiUrl}/api/Partnership/CreatePartnership/${userId}`,
    postRequestOptions(partnership)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updatePartnership(userId, partnership) {
  return fetch(
    `${mainApiUrl}/api/Partnership/UpdatePartnership/${userId}`,
    postRequestOptions(partnership)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createUserPartnership(user) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/Partnership/CreateUserPartnership/${userId}`,
    postRequestOptions(user)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function deleteUserPartnership(user) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/Partnership/DeleteUserPartnership/${userId}`,
    postRequestOptions(user)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getPackageListByEntityIdPartnershipId(entityId, partnershipId) {
  return fetch(
    `${mainApiUrl}/api/Package/GetListByEntityWPartnership/${entityId}/${partnershipId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updatePartnershipPackages(buttonPackages) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/Package/UpdatePartnershipPackages/${userId}`,
    postRequestOptions(buttonPackages)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterById(matterId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetMatter/${matterId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListByPartnershipIdEntityId(partnershipId, entityId, userId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetListByPartnershipEntity/${entityId}/${partnershipId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getNextLawfirmMatterNumber(partnershipId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetNextLawfirmMatterNumber/${partnershipId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListWithActivitiesByPartnershipIdDateRange(
  partnershipId,
  startDate,
  endDate
) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetMatterListWithActivitiesByPartnershipIdDateRange/${partnershipId}?startDate=${startDate}&endDate=${endDate}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListWithActivitiesOrExpensesByPartnershipIdsDateRange(
  partnershipIds,
  startDate,
  endDate
) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetMatterListWithActivitiesOrExpensesByPartnershipIdsDateRange?partnershipIds=${partnershipIds}&startDate=${startDate}&endDate=${endDate}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function generateMattersInvoices(userId, matters, startDate, endDate) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GenerateMattersInvoices/${userId}`,
    postRequestOptions({
      matters: matters,
      startDate: startDate,
      endDate: endDate,
    })
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getLastInvoiceInfoValues(partnershipId, matterIds) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetLastInvoiceInfoValues/${partnershipId}?matterIds=${matterIds}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMasterMatterListByUserId(userId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetMasterMatterListByUserId/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getChildMatterListByMatterId(matterId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetChildMatterListByMatterId/${matterId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListByEntityIdIncludeTotals(
  entityId,
  partnershipId,
  currencyId,
  year,
  geoCategoryId,
  matterTypeId,
  status
) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Matter/GetListByEntityIdIncludeTotals?userId=${
      currentUser.userId
    }&entityid=${entityId}&timezoneId=${
      currentUser.timezoneId
    }&partnershipId=${partnershipId}&currencyId=${currencyId}&date=${year.toLocaleDateString()}&geoCategoryId=${geoCategoryId}&matterTypeId=${matterTypeId}&status=${status}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListWithMasterByEntityIdIncludeTotals(
  entityId,
  partnershipId,
  currencyId,
  year,
  geoCategoryId,
  matterTypeId,
  status
) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Matter/GetListWithMasterByEntityIdIncludeTotals?userId=${
      currentUser.userId
    }&entityid=${entityId}&timezoneId=${
      currentUser.timezoneId
    }&partnershipId=${partnershipId}&currencyId=${currencyId}&date=${year.toLocaleDateString()}&geoCategoryId=${geoCategoryId}&matterTypeId=${matterTypeId}&status=${status}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListByPartnershipIds(
  userId,
  partnershipIds,
  entityId,
  includeInactive = false
) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetListByPartnershipIds?userId=${userId}&partnershipIds=${partnershipIds}&entityId=${entityId}&includeInactive=${includeInactive}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListByPartnershipIdsForAdmin(partnershipIds) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetListByPartnershipIdsForAdmin?partnershipIds=${partnershipIds}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getBillerListByMatterId(matterId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetBillerListByMatterId/${matterId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getBillerListByEntityId(entityId) {
  return fetch(
    `${mainApiUrl}/api/User/GetBillerListByEntityId/${entityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getBillerListByMatterIdIncludeActivities(matterId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetBillerListByMatterIDIncludeActivities/${matterId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getBillerListByMatterIdUserIdIncludeActivities(matterId, userId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetBillerListByMatterIDIncludeActivities/${matterId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getBillerMatterListByEntityIdIncludeTimes(
  entityId,
  partnershipId,
  currencyId,
  year,
  geoCategoryId,
  matterTypeId,
  status
) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Matter/GetBillerMatterListByEntityIDIncludeTimes?userId=${
      currentUser.userId
    }&entityid=${entityId}&timezoneId=${
      currentUser.timezoneId
    }&partnershipId=${partnershipId}&currencyId=${currencyId}&date=${year.toLocaleDateString()}&geoCategoryId=${geoCategoryId}&matterTypeId=${matterTypeId}&status=${status}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListByUserId(userId, roleId, isForAdmin) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetMatterListByUser/${userId}/${roleId}?isForAdmin=${
      isForAdmin === true ? "true" : "false"
    }`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListByBillerEntityId(billerEntityId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetListByBillerEntityId/${billerEntityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getPartnershipListByUserId(userId, roleId) {
  return fetch(
    `${mainApiUrl}/api/Partnership/GetPartnershipListByUser/${userId}/${roleId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterListByUserIdWithBudget(userId, roleId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetMatterListByUserWithBudget/${userId}/${roleId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getUserListByMatterId(matterId, roleId = 0) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetUserListByMatter/${matterId}/${roleId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createMatter(matter) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/Matter/CreateMatter/${userId}`,
    postRequestOptions(matter)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateMatter(matter) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/Matter/UpdateMatter/${userId}`,
    postRequestOptions(matter)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getBudgetListByMatterId(matterId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetBudgetListByMatter/${matterId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createBudget(budget) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/Matter/CreateBudget/${userId}`,
    postRequestOptions(budget)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateBudget(budget) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/Matter/UpdateBudget/${userId}`,
    postRequestOptions(budget)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createUserMatter(user) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/Matter/CreateUserMatter/${userId}`,
    postRequestOptions(user)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function deleteUserMatter(user) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/Matter/DeleteUserMatter/${userId}`,
    postRequestOptions(user)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getActivityCodeListByMatterId(matterId) {
  return fetch(
    `${mainApiUrl}/api/ActivityCode/GetListByMatter/${matterId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getActivityCodeListByPackageMatterSelected(packageId, matterId) {
  return fetch(
    `${mainApiUrl}/api/ActivityCode/GetListByPackage_MatterSelected/${packageId}/${matterId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterActivityCode(matterActivityCodeId) {
  return fetch(
    `${mainApiUrl}/api/ActivityCode/GetMatterActivityCode/${matterActivityCodeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterActivityCodeByMatterIdActivityCodeId(matterId, actCodeId) {
  return fetch(
    `${mainApiUrl}/api/ActivityCode/GetMatterActivityCode/${matterId}/${actCodeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createMatterActivityCode(actCode) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/ActivityCode/CreateMatterActivityCode/${userId}`,
    postRequestOptions(actCode)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateMatterActivityCode(actCode) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${mainApiUrl}/api/ActivityCode/UpdateMatterActivityCode/${userId}`,
    postRequestOptions(actCode)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function startActivity(activity) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${activityApiUrl}/api/Activity/StartActivity/${userId}`,
    postRequestOptions(activity)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateActivity(activity) {
  let userId = userService.getLoggedInUser().userId;
  return fetch(
    `${activityApiUrl}/api/Activity/UpdateActivity/${userId}`,
    postRequestOptions(activity)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function excludeActivityFromInvoice(activityId, excludeFromInvoice) {
  return fetch(
    `${activityApiUrl}/api/Activity/ExcludeFromInvoice/${activityId}/${excludeFromInvoice}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getRunningActivity(billerId) {
  return fetch(
    `${activityApiUrl}/api/Activity/GetRunningActivity/${billerId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getExplanationCodes() {
  return fetch(
    `${activityApiUrl}/api/Activity/GetExplanationCodes`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getActivityListByMatterUser(matterId, userId) {
  return fetch(
    `${activityApiUrl}/api/Activity/GetActivityListByMatterUser/${matterId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getActivitiesByMatterIdDateRange(
  matterId,
  startDate,
  endDate,
  billerId
) {
  let url = `${activityApiUrl}/api/Activity/GetActivitiesByMatterIdDateRange?matterId=${matterId}&startDate=${startDate}&endDate=${endDate}`;

  if (billerId !== undefined && billerId > 0) {
    url += `&billerId=${billerId}`;
  }

  return fetch(url, getRequestOptions())
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getActivityListByBillerId(userId) {
  return fetch(
    `${activityApiUrl}/api/Activity/GetActivityListByBillerId/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getActivity(activityId) {
  return fetch(
    `${activityApiUrl}/api/Activity/GetActivity/${activityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getSuspendedActivityListByBillerId(userId, includeHidden) {
  return fetch(
    `${activityApiUrl}/api/Activity/GetSuspendedActivityListByBillerId/${userId}/${includeHidden}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getSuspendedActivityListByResponsibleLawyerId(userId) {
  return fetch(
    `${activityApiUrl}/api/Activity/GetSuspendedActivityListByResponsibleLawyerId/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function canUserBill(userId) {
  return fetch(
    `${mainApiUrl}/api/User/CanUserBill/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterByMatterIdDate(matterId, date, currencyId) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Matter/GetMatterByMatterIdDate?matterId=${matterId}&timezoneId=${currentUser.timezoneId}&date=${date}&currencyId=${currencyId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function registerUser(user) {
  return fetch(`${authApiUrl}/api/Home/RegisterUser`, postRequestOptions(user))
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function unregisterUser(user) {
  return fetch(
    `${authApiUrl}/api/Home/UnregisterUser`,
    postRequestOptions(user)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getPartnershipMatterRoleList(userId, entityId) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Partnership/GetPartnershipMatterRoleList?userId=${userId}&entityId=${entityId}&updateUserId=${currentUser.userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getMatterRoleList(userId, entityId) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Partnership/GetMatterRoleList?userId=${userId}&entityId=${entityId}&updateUserId=${currentUser.userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function savePartnershipMatterRoleList(userId, list) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Partnership/SavePartnershipMatterRoleList?userId=${userId}&updateUserId=${currentUser.userId}`,
    postRequestOptions(list)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function saveMatterRoleList(userId, list) {
  let currentUser = userService.getLoggedInUser();
  return fetch(
    `${mainApiUrl}/api/Partnership/SaveMatterRoleList?userId=${userId}&updateUserId=${currentUser.userId}`,
    postRequestOptions(list)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getSpendByLawfirmChartData(
  userId,
  entityId,
  startDate,
  endDate,
  currencyId,
  timezoneId,
  geoCatId,
  matTypeId,
  busActId
) {
  return fetch(
    `${mainApiUrl}/api/Chart/GetSpendByLawfirmChartData?userId=${userId}&entityId=${entityId}&startDate=${startDate}&endDate=${endDate}&currencyId=${currencyId}&timezoneId=${timezoneId}&geoCategoryId=${geoCatId}&matterTypeId=${matTypeId}&businessActivityId=${busActId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getSpendToBudgetChartData(
  userId,
  matterId,
  startDate,
  endDate,
  currencyId,
  timezoneId
) {
  return fetch(
    `${mainApiUrl}/api/Chart/GetSpendToBudgetChartData?userId=${userId}&matterId=${matterId}&startDate=${startDate}&endDate=${endDate}&currencyId=${currencyId}&timezoneId=${timezoneId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getSpendByClientChartData(
  userId,
  entityId,
  startDate,
  endDate,
  currencyId,
  timezoneId,
  geoCatId,
  matTypeId,
  busActId
) {
  return fetch(
    `${mainApiUrl}/api/Chart/GetSpendByClientChartData?userId=${userId}&entityId=${entityId}&startDate=${startDate}&endDate=${endDate}&currencyId=${currencyId}&timezoneId=${timezoneId}&geoCategoryId=${geoCatId}&matterTypeId=${matTypeId}&businessActivityId=${busActId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getPartnershipRate(partnershipId, userId) {
  return fetch(
    `${mainApiUrl}/api/Rate/GetPartnershipRate?partnershipId=${partnershipId}&userId=${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getAdminNotices(userId, type) {
  return fetch(
    `${mainApiUrl}/api/Admin/GetAdminNotices?userId=${userId}&type=${type}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function insertPartnershipRate(
  userPartnershipId,
  rate,
  effectiveDate,
  currencyId,
  userId
) {
  var data = {
    userPartnershipId,
    rate,
    effectiveDate,
    currencyId,
    userId,
  };
  return fetch(
    `${mainApiUrl}/api/Rate/InsertPartnershipRate`,
    postRequestOptions(data)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function insertPartnershipRateDateRange(
  userId,
  userPartnershipId,
  partnershipId,
  rate,
  currencyId,
  effectiveDate,
  effectiveDateEnd
) {
  var data = {
    userId,
    userPartnershipId,
    partnershipId,
    rate,
    currencyId,
    effectiveDate,
    effectiveDateEnd,
  };
  return fetch(
    `${mainApiUrl}/api/Rate/InsertPartnershipRateDateRange`,
    postRequestOptions(data)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updatePartnershipRate(partnershipId, userId, rate, effectiveDate) {
  var data = {
    partnershipId,
    userId,
    rate,
    effectiveDate,
  };
  return fetch(
    `${mainApiUrl}/api/Rate/UpdatePartnershipRate`,
    postRequestOptions(data)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function updatePartnershipRateAndMatters(
  userId,
  userPartnershipId,
  partnershipId,
  rate,
  currencyId,
  effectiveDate
) {
  var data = {
    userId,
    userPartnershipId,
    partnershipId,
    rate,
    currencyId,
    effectiveDate,
  };
  return fetch(
    `${mainApiUrl}/api/Rate/UpdatePartnershipRateAndMatters`,
    postRequestOptions(data)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateRatesBatch(list, chosenUpdateType, matterId) {
  return fetch(
    `${mainApiUrl}/api/Rate/UpdateMatterRatesBatch/${chosenUpdateType}/${matterId}`,
    postRequestOptions(list)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getUserMatter(matterId, userId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetUserMatter?matterId=${matterId}&userId=${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getMatterRate(matterId, userId) {
  return fetch(
    `${mainApiUrl}/api/Rate/GetMatterRate?matterId=${matterId}&userId=${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function insertMatterRate(
  userMatterId,
  currencyId,
  rate,
  effectiveDate,
  userId
) {
  var data = {
    userMatterId,
    currencyId,
    rate,
    effectiveDate,
    userId,
  };
  return fetch(
    `${mainApiUrl}/api/Rate/InsertMatterRate`,
    postRequestOptions(data)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function insertMatterRateDateRange(
  userMatterId,
  currencyId,
  rate,
  effectiveDate,
  effectiveDateEnd,
  userId
) {
  var data = {
    userMatterId,
    currencyId,
    rate,
    effectiveDate,
    effectiveDateEnd,
    userId,
  };
  return fetch(
    `${mainApiUrl}/api/Rate/InsertMatterRateDateRange`,
    postRequestOptions(data)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function updateMatterRate(matterId, userId, rate, effectiveDate) {
  var data = {
    matterId,
    userId,
    rate,
    effectiveDate,
  };
  return fetch(
    `${mainApiUrl}/api/Rate/UpdateMatterRate`,
    postRequestOptions(data)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateMatterRateAndPartnership(
  matterId,
  partnershipId,
  userId,
  rate,
  effectiveDate
) {
  var data = {
    matterId,
    userId,
    rate,
    effectiveDate,
    partnershipId,
  };
  return fetch(
    `${mainApiUrl}/api/Rate/UpdateMatterRateAndPartnershipOnly`,
    postRequestOptions(data)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getAlertList(userId) {
  return fetch(
    `${mainApiUrl}/api/Notification/GetAlertList?userId=${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateAlertStatus(notificationTypeId, userId, isOn, updateUserId) {
  return fetch(
    `${mainApiUrl}/api/Notification/UpdateAlertStatus/${notificationTypeId}/${userId}/${isOn}/${updateUserId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getPartnershipListForAlerts(userId, entityId, notificationTypeId) {
  return fetch(
    `${mainApiUrl}/api/Partnership/GetPartnershipListForAlerts?userId=${userId}&entityId=${entityId}&notificationTypeId=${notificationTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function getMatterListForAlerts(userId, entityId, notificationTypeId) {
  return fetch(
    `${mainApiUrl}/api/Matter/GetMatterListForAlerts?userId=${userId}&entityId=${entityId}&notificationTypeId=${notificationTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getActivityNotes(email, activityId, password) {
  return fetch(
    `${activityApiUrl}/api/Activity/GetActivityNotes?email=${email}&activityId=${activityId}&password=${password}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateAlertPartnerships(
  userId,
  updateUserId,
  alert,
  selectedPartnershipList
) {
  var vm = {
    notificationType: alert,
    partnershipList: selectedPartnershipList,
  };
  return fetch(
    `${mainApiUrl}/api/Notification/UpdateAlertPartnerships/${userId}/${updateUserId}`,
    postRequestOptions(vm)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateAlertMatters(userId, updateUserId, alert, selectedMatterList) {
  var vm = {
    notificationType: alert,
    matterList: selectedMatterList,
  };
  return fetch(
    `${mainApiUrl}/api/Notification/UpdateAlertMatters/${userId}/${updateUserId}`,
    postRequestOptions(vm)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function changePassword(entityId, username, oldPwd, newPwd) {
  return fetch(
    `${mainApiUrl}/api/User/ChangePassword`,
    postRequestOptions({
      entityId: entityId,
      username: username,
      oldPassword: oldPwd,
      newPassword: newPwd,
    })
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function resetPassword(userId, password) {
  return fetch(
    `${mainApiUrl}/api/User/ResetPassword`,
    postRequestOptions({ userId, newPassword: password })
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getInvoiceListByPartnershipsEntity(
  partnershipIds,
  entityId,
  entityRoleId,
  invoiceStatusId,
  zeroOrHigher,
  startDate,
  endDate,
  currencyId,
  includePayments
) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetListByPartnershipIds`,
    postRequestOptions({
      partnershipIds,
      entityId,
      entityRoleId,
      invoiceStatusId,
      zeroOrHigher,
      startDate,
      endDate,
      currencyId,
      includePayments,
    })
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getInvoiceByInvoiceNumber(invoiceNumber) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetByInvoiceNumber?invoiceNumber=${invoiceNumber}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getInvoiceListByInvoiceReviewerId(
  invoiceReviewerId,
  zeroOrHigher,
  startDate,
  endDate
) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetListByInvoiceReviewerID/${invoiceReviewerId}`,
    postRequestOptions({
      zeroOrHigher,
      startDate,
      endDate,
    })
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getInvoiceStatuses() {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetInvoiceStatuses`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function computeInvoiceBalance(partnershipId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/ComputeBalance/${partnershipId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getInvoiceById(invoiceId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetInvoice/${invoiceId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getTotalPreviouslyBilled(invoiceId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetTotalPreviouslyBilled/${invoiceId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getVendors() {
  return fetch(`${mainApiUrl}/api/Invoice/GetVendors`, getRequestOptions())
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createVendor(vendorName) {
  return fetch(
    `${mainApiUrl}/api/Invoice/CreateVendor`,
    postRequestOptions({ vendorName: vendorName })
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function deleteVendor(vendorId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/DeleteVendor/${vendorId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getActivityListByInvoiceId(invoiceId, currencyId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetActivityListByInvoice/${invoiceId}/${currencyId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getExpenseListByInvoiceId(invoiceId, currencyId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetExpenseListByInvoice/${invoiceId}/${currencyId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getExpenseListByPartnershipIds(partnershipIdsCommaDelimited) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetExpenseListByPartnershipIds?partnershipIds=${partnershipIdsCommaDelimited}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getExpenseListByUserId(userId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetExpensesByUserId/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function resendInvitationEmail(fromUserId, toUserId) {
  return fetch(
    `${mainApiUrl}/api/User/ResendInvitationMail?fromUserId=${fromUserId}&toUserId=${toUserId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getUserListByEntityId(entityId) {
  return fetch(
    `${mainApiUrl}/api/User/GetUserListByEntityId?entityId=${entityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createInvoice(invoice, userId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/CreateInvoice/${userId}`,
    postRequestOptions(invoice)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateInvoice(invoice, userId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/UpdateInvoice/${userId}`,
    postRequestOptions(invoice)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createExpense(expense, userId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/CreateExpense/${userId}`,
    postRequestOptions(expense)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateExpense(expense, userId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/UpdateExpense/${userId}`,
    postRequestOptions(expense)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function deleteExpense(expense, userId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/DeleteExpense/${userId}`,
    postRequestOptions(expense)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function deleteExpenseDocument(expenseDocumentId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/DeleteExpenseDocument/${expenseDocumentId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function saveUserPartnershipAssociationList(partnershipId, userId, userList) {
  return fetch(
    `${mainApiUrl}/api/Partnership/SaveUserPartnershipAssociationList/${partnershipId}/${userId}`,
    postRequestOptions(userList)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateMatterMasterMatterId(matterId, masterMatterId) {
  return fetch(
    `${mainApiUrl}/api/Matter/UpdateMatterMasterMatterId/${matterId}/${masterMatterId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function resetAllMattersMasterMatter(masterMatterId) {
  return fetch(
    `${mainApiUrl}/api/Matter/ResetAllMattersMasterMatter/${masterMatterId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function saveUserMatterAssociationList(userId, matterId, userList) {
  return fetch(
    `${mainApiUrl}/api/Matter/SaveUserMatterAssociationList/${userId}/${matterId}`,
    postRequestOptions(userList)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function updateMatterActivityCodes(matterId, updateUserId, activityCodes) {
  return fetch(
    `${mainApiUrl}/api/Package/UpdateMatterActivityCodes/${matterId}/${updateUserId}`,
    postRequestOptions(activityCodes)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getSpendByMatterChartData(
  userId,
  partnershipIds,
  entityId,
  startDate,
  endDate,
  currencyId,
  timezoneId,
  geoCatId,
  matTypeId,
  busActId
) {
  let payload = {
    userId,
    partnershipIds,
    entityId,
    startDate,
    endDate,
    currencyId,
    timezoneId,
    geoCategoryId: geoCatId,
    matterTypeId: matTypeId,
    businessActivityId: busActId,
  };
  return fetch(
    `${mainApiUrl}/api/Chart/GetSpendByMatterChartData`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getSpendByBillerChartData(
  userId,
  partnershipIds,
  entityId,
  startDate,
  endDate,
  currencyId,
  timezoneId,
  geoCatId,
  matTypeId,
  busActId
) {
  let payload = {
    userId,
    partnershipIds,
    entityId,
    startDate,
    endDate,
    currencyId,
    timezoneId,
    geoCategoryId: geoCatId,
    matterTypeId: matTypeId,
    businessActivityId: busActId,
  };
  return fetch(
    `${mainApiUrl}/api/Chart/GetSpendByBillerChartData`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getSpendByActivityChartData(
  userId,
  partnershipIds,
  entityId,
  startDate,
  endDate,
  currencyId,
  timezoneId,
  geoCatId,
  matTypeId,
  busActId
) {
  let payload = {
    userId,
    partnershipIds,
    entityId,
    startDate,
    endDate,
    currencyId,
    timezoneId,
    geoCategoryId: geoCatId,
    matterTypeId: matTypeId,
    businessActivityId: busActId,
  };
  return fetch(
    `${mainApiUrl}/api/Chart/GetSpendByActivityChartData`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function insertChartHistory(chartHistory) {
  return fetch(
    `${mainApiUrl}/api/Chart/InsertChartHistory`,
    postRequestOptions(chartHistory)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getChartHistory(userId) {
  return fetch(
    `${mainApiUrl}/api/Chart/GetChartHistory/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getRateHistoryReport(
  startDate,
  endDate,
  currencyId,
  type,
  partnershipId,
  matterId,
  userId
) {
  let currentUser = userService.getLoggedInUser();
  let obj = {
    startDate,
    endDate,
    currencyId,
    reportType: type,
    matterId: matterId == undefined ? 0 : matterId,
    partnershipId: partnershipId == undefined ? 0 : partnershipId,
    userId: userId == undefined ? 0 : userId,
  };
  return fetch(
    `${reportApiUrl}/api/Reports/BillerRateHistoryReport`,
    postRequestOptions(obj)
  )
    .then((resp) => {
      return resp.blob();
    })
    .then((blob) => {
      return blob;
    });
}

function getReport(
  reportId,
  startDate,
  endDate,
  currencyId,
  lawfirmIds,
  matterIds,
  type,
  selectedOrder,
  password,
  reportOption
) {
  let url = "";
  switch (parseInt(reportId)) {
    case 1: //activities by duration
      url = `${reportApiUrl}/api/Reports/ActivitiesByDuration`;
      break;
    case 2: //activity detail
      url = `${reportApiUrl}/api/Reports/ActivityDetails`;
      break;
    case 3: //activity efficiency
      url = `${reportApiUrl}/api/Reports/ActivityEfficiency`;
      break;
    case 4: //matter exception
      url = `${reportApiUrl}/api/Reports/MatterException`;
      break;
    case 5: //spend by biller
      url = `${reportApiUrl}/api/Reports/SpendByBiller`;
      break;
    case 6: //spend to budget
      url = `${reportApiUrl}/api/Reports/SpendToBudget`;
      break;
    case 7: //spend by matter
      url = `${reportApiUrl}/api/Reports/SpendByMatter`;
      break;
    case 8: //spend by matter combined
      url = `${reportApiUrl}/api/Reports/SpendByMatterCombined`;
      break;
    case 9: //suspended activity
      url = `${reportApiUrl}/api/Reports/SuspendedActivity`;
      break;
    case 10: //revision
      url = `${reportApiUrl}/api/Reports/Revision`;
      break;
    case 11:
      url = `${reportApiUrl}/api/Reports/SpendByLawfirm`;
      break;
    case 12:
      url = `${reportApiUrl}/api/Reports/ActivityDetailDuration`;
      break;
    case 13:
      url = `${reportApiUrl}/api/Reports/SuspendedActivitiesDuration`;
      break;
    case 14:
      url = `${reportApiUrl}/api/Reports/SpendByActivity`;
      break;
    case 15:
      url = `${reportApiUrl}/api/Reports/DailyBreakdown`;
      break;
    case 16:
      url = `${reportApiUrl}/api/Reports/BillerPerformance`;
      break;
    case 17:
      url = `${reportApiUrl}/api/Reports/CsvBillingSystemReport`;
      break;
    case 18:
      url = `${reportApiUrl}/api/Reports/FlaggedActivities`;
      break;
    case 19:
      url = `${reportApiUrl}/api/Reports/BillerHoursSummary`;
      break;
    case 20:
      url = `${reportApiUrl}/api/Reports/AccountsReceivable`;
      break;
    case 22:
      url = `${reportApiUrl}/api/Reports/BillingReport`;
      break;
    case 23:
      url = `${reportApiUrl}/api/Reports/InvoiceStatusReport`;
      break;
    case 25:
      url = `${reportApiUrl}/api/Reports/LawRateReport`;
      break;
  }
  //return url;
  let currentUser = userService.getLoggedInUser();
  let obj = {
    startDate,
    endDate,
    currencyId,
    lawfirmIds,
    reportType: type,
    orderByColumn: selectedOrder,
    matterIds: matterIds == null ? "" : matterIds,
    timezoneId: currentUser.timezoneId,
    userId: currentUser.userId,
    password,
    reportOption,
  };
  return fetch(url, postRequestOptions(obj))
    .then((resp) => {
      return resp.blob();
    })
    .then((blob) => {
      return blob;
    });
}

function getPaymentReport(billerEntityId, startDate, endDate, reportType) {
  return fetch(
    `${reportApiUrl}/api/Reports/PaymentsReport/${billerEntityId}`,
    postRequestOptions({ startDate, endDate, reportType })
  )
    .then((resp) => {
      return resp.blob();
    })
    .then((blob) => {
      return blob;
    });
}

function getPapercutImportReport(userId) {
  return fetch(
    `${reportApiUrl}/api/Reports/CsvPapercutImportReport`,
    postRequestOptions({ userId })
  )
    .then((resp) => {
      return resp.blob();
    })
    .then((blob) => {
      return blob;
    });
}

function getSearchReport(searchTerm, selectedExportType) {
  let currentUser = userService.getLoggedInUser();
  let obj = {
    entityId: currentUser.entityId,
    searchTerm: searchTerm,
    userId: currentUser.userId,
    reportType: selectedExportType,
  };
  return fetch(
    `${reportApiUrl}/api/Reports/SearchReport`,
    postRequestOptions(obj)
  )
    .then((resp) => {
      return resp.blob();
    })
    .then((blob) => {
      return blob;
    });
}

function getLedesInvoice(invoiceId) {
  return fetch(
    `${reportApiUrl}/api/Reports/LedesInvoice/${invoiceId}`,
    getRequestOptions()
  )
    .then((resp) => {
      return resp.blob();
    })
    .then((blob) => {
      return blob;
    });
}

function getInvoicePdf(invoiceId) {
  return fetch(
    `${reportApiUrl}/api/Reports/Invoice/${invoiceId}`,
    postRequestOptions()
  )
    .then((resp) => {
      return resp.blob();
    })
    .then((blob) => {
      return blob;
    });
}

function getInternalLawfirmListByEntityId(entityId) {
  return fetch(
    `${mainApiUrl}/api/Entity/GetInternalLawfirmListByEntityId/${entityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getNextInvoiceNumber(partnershipId, userEntityId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetNextInvoiceNumber/${partnershipId}/${userEntityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getInternalClientListByEntityId(entityId) {
  return fetch(
    `${mainApiUrl}/api/Entity/GetInternalClientListByEntityId/${entityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function sendInvoiceByEmail(invoiceId) {
  return fetch(
    `${reportApiUrl}/api/Reports/SendInvoiceByEmail/${invoiceId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function uploadEntityLogo(entityId, file) {
  var data = new FormData();
  data.append("file", file);
  return fetch(`${mainApiUrl}/api/Entity/UploadEntityLogo/${entityId}`, {
    method: "POST",
    headers: {
      Authorization: getToken(),
    },
    body: data,
  })
    .then(handleResponse)
    .then((r) => {
      return r;
    }, handleError);
}

function uploadExpenseDocument(expenseId, file) {
  var data = new FormData();
  data.append("file", file);
  return fetch(`${mainApiUrl}/api/Invoice/UploadExpenseDocument/${expenseId}`, {
    method: "POST",
    headers: {
      Authorization: getToken(),
    },
    body: data,
  })
    .then(handleResponse)
    .then((r) => {
      return r;
    }, handleError);
}

function uploadPapercutExpenses(userId, file) {
  var data = new FormData();
  data.append("file", file);
  return fetch(`${mainApiUrl}/api/Invoice/UploadPapercutExpenses/${userId}`, {
    method: "POST",
    headers: {
      Authorization: getToken(),
    },
    body: data,
  })
    .then(handleResponse)
    .then((r) => {
      return r;
    }, handleError);
}

function getExpenseDocuments(expenseId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetExpenseDocuments/${expenseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getExpenseListByMatterIdDateRange(matterId, startDate, endDate) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetExpenseListByMatterIdDateRange/${matterId}?startDate=${startDate}&endDate=${endDate}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function copyOrMoveActivitiesAndExpenses(
  toMatterId,
  copyOrMove,
  activityIds,
  expenseIds
) {
  return fetch(
    `${mainApiUrl}/api/Invoice/CopyOrMoveActivitiesAndExpenses/${toMatterId}`,
    postRequestOptions({ copyOrMove, activityIds, expenseIds })
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function createPayment(payment) {
  return fetch(
    `${mainApiUrl}/api/Invoice/CreatePayment`,
    postRequestOptions(payment)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function updatePayment(payment) {
  return fetch(
    `${mainApiUrl}/api/Invoice/UpdatePayment/${payment.paymentId}`,
    postRequestOptions(payment)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function deletePayment(paymentId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/DeletePayment/${paymentId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function createInvoicePayment(invoicePayment) {
  return fetch(
    `${mainApiUrl}/api/Invoice/CreateInvoicePayment`,
    postRequestOptions(invoicePayment)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function updateInvoicePayment(invoicePayment) {
  return fetch(
    `${mainApiUrl}/api/Invoice/UpdateInvoicePayment/${invoicePayment.invoicePaymentId}`,
    postRequestOptions(invoicePayment)
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}
function deleteInvoicePayment(invoicePaymentId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/DeleteInvoicePayment/${invoicePaymentId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getPaymentsByBillingEntityId(entityId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetPaymentsByBillingEntityId/${entityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

function getPayment(paymentId) {
  return fetch(
    `${mainApiUrl}/api/Invoice/GetPayment/${paymentId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then((data) => {
      return data;
    }, handleError);
}

export const userMatterRoles = {
  authViewer: {
    id: 1,
    name: "Viewer",
  },
  biller: {
    id: 2,
    name: "Biller",
  },
  respLawyer: {
    id: 3,
    name: "Responsible Lawyer",
  },
};

export const userMatterRolesArray = [
  {
    id: 1,
    name: "Viewer",
  },
  {
    id: 2,
    name: "Biller",
  },
  {
    id: 3,
    name: "Responsible Lawyer",
  },
  {
    id: 100,
    name: "Law Firm Viewer",
  },
];

export const api = {
  mainApiUrl,
  authApiUrl,
  getItemListByEntityId,
  getEntityById,
  getEntityTypeList,
  getAllClientEntityList,
  getAllLawfirmEntityList,
  inviteEntity,
  createItem,
  updateItem,
  getEntityListByEntityId,
  getUserListByUserId,
  getTimezoneList,
  uploadEntityLogo,
  uploadExpenseDocument,
  uploadPapercutExpenses,
  getExpenseDocuments,
  getCurrencyList,
  getExpenseListByMatterIdDateRange,
  copyOrMoveActivitiesAndExpenses,
  createUser,
  updateUser,
  getMatterListWithActivitiesByPartnershipIdDateRange,
  getMatterListWithActivitiesOrExpensesByPartnershipIdsDateRange,
  generateMattersInvoices,
  createEntity,
  updateEntity,
  getCategoryPackageListByEntityId,
  getLastInvoiceInfoValues,
  createPackage,
  updatePackage,
  getPartnershipListByEntityId,
  getPartnershipById,
  getAuthViewerListByPartnershipId,
  getBillerListByPartnershipId,
  getRespLawyerListByPartnershipId,
  getPackageListByPartnershipId,
  createPartnership,
  getLedesInvoice,
  updatePartnership,
  createUserPartnership,
  deleteUserPartnership,
  getPackageListByEntityIdPartnershipId,
  updatePartnershipPackages,
  getUserListByPartnership,
  getMatterById,
  createMatter,
  updateMatter,
  getBudgetListByMatterId,
  createBudget,
  getPaymentsByBillingEntityId,
  updateBudget,
  getUserListByMatterId,
  createUserMatter,
  sendInvoiceByEmail,
  deleteUserMatter,
  getMatterListByPartnershipIdEntityId,
  getNextLawfirmMatterNumber,
  getActivityCodeListByPackageMatterSelected,
  getMatterActivityCode,
  getPayment,
  getMatterActivityCodeByMatterIdActivityCodeId,
  getActivityCodeListByMatterId,
  createMatterActivityCode,
  updateMatterActivityCode,
  getMatterListByUserId,
  getMatterListByBillerEntityId,
  getPartnershipListByUserId,
  getMatterListByUserIdWithBudget,
  startActivity,
  updateActivity,
  getRunningActivity,
  getExplanationCodes,
  getActivityListByMatterUser,
  getActivityListByBillerId,
  getActivity,
  getSuspendedActivityListByBillerId,
  getSuspendedActivityListByResponsibleLawyerId,
  getMatterListByEntityIdIncludeTotals,
  getMatterListWithMasterByEntityIdIncludeTotals,
  getBillerMatterListByEntityIdIncludeTimes,
  getBillerListByMatterIdIncludeActivities,
  getBillerListByMatterIdUserIdIncludeActivities,
  canUserBill,
  getMatterByMatterIdDate,
  registerUser,
  unregisterUser,
  getPaymentReport,
  getPartnershipMatterRoleList,
  savePartnershipMatterRoleList,
  getMatterRoleList,
  saveMatterRoleList,
  getUserByUserId,
  getSpendByLawfirmChartData,
  getSpendByMatterChartData,
  getSpendByActivityChartData,
  getSpendByBillerChartData,
  getSpendToBudgetChartData,
  getUserTypes,
  getReport,
  getRateHistoryReport,
  getSearchReport,
  getPapercutImportReport,
  getMatterListByPartnershipIds,
  getMatterListByPartnershipIdsForAdmin,
  getPartnershipRate,
  insertPartnershipRate,
  updatePartnershipRate,
  updatePartnershipRateAndMatters,
  updateMatterRateAndPartnership,
  updateMatterRate,
  insertMatterRate,
  getMatterRate,
  insertPartnershipRateDateRange,
  insertMatterRateDateRange,
  updateRatesBatch,
  getAlertList,
  updateAlertStatus,
  getMatterListForAlerts,
  getPartnershipListForAlerts,
  excludeActivityFromInvoice,
  updateAlertPartnerships,
  updateAlertMatters,
  resetPassword,
  changePassword,
  getInvoiceListByPartnershipsEntity,
  getPartnershipListByManager,
  getInvoiceStatuses,
  getInvoiceListByInvoiceReviewerId,
  getInvoiceByInvoiceNumber,
  computeInvoiceBalance,
  getInvoiceById,
  getTotalPreviouslyBilled,
  getActivityListByInvoiceId,
  getExpenseListByInvoiceId,
  getExpenseListByPartnershipIds,
  getExpenseListByUserId,
  createInvoice,
  updateInvoice,
  createExpense,
  updateExpense,
  deleteExpense,
  deleteExpenseDocument,
  getUserListByUserIdIncludePartnership,
  getAllClientEntityListByBillerEntityId,
  changeAlreadyRemindedFlagOnActivity,
  getInvoicePdf,
  saveUserPartnershipAssociationList,
  getUserListByUserIdIncludePartnershipForMatter,
  saveUserMatterAssociationList,
  updateMatterActivityCodes,
  getDefaultEmailText,
  getSpendByClientChartData,
  resendInvitationEmail,
  getActivityNotes,
  getInternalClientListByEntityId,
  getInternalLawfirmListByEntityId,
  getNextInvoiceNumber,
  getChartHistory,
  insertChartHistory,
  getUserPartnershipDefaultRates,
  getPartnershipListByInvoiceReviewerId,
  getAdminNotices,
  getUserListByEntityId,
  getUserByEmail,
  getChildMatterListByMatterId,
  updateMatterMasterMatterId,
  resetAllMattersMasterMatter,
  getMasterMatterListByUserId,
  getBillerListByMatterId,
  getActivitiesByMatterIdDateRange,
  getUserMatter,
  getVendors,
  createVendor,
  deleteVendor,
  createInvoicePayment,
  updateInvoicePayment,
  deleteInvoicePayment,
  createPayment,
  updatePayment,
  deletePayment,
  getBillerListByEntityId,
};
