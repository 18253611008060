import _ from "lodash";
import { leguardNav } from "../Layout/AppNav/NavItems";

export const navBarHelper = {
  getNavigationBar,
};

function getNavigationBar(user) {
  let nav = [
    {
      label: "Home",
      to: "#/dashboard",
    },
    {
      hasToBeRemoved: (user) => {
        return user.entityRoleId == 1;
      },
      label: "Activities",
      to: "#/activity",
    },
    {
      hasToBeRemoved: (user) => {
        return (
          user.highestRole == 2 ||
          (user.highestRole == 0 && user.entityRoleId == 2)
        );
      },
      label: "Charts",
      to: "#/charts",
    },
    {
      // hasToBeRemoved: (user) => {
      //   return user.systemRoleId !== 2;
      // },
      label: "Invoices",
      to: "#/invoices",
    },
    {
      label: "Reports",
      content: [
        {
          hasToBeRemoved: (user) => {
            return user.entityRoleId == 1 || user.systemRoleId != 2;
          },
          label: "Accounts Receivable",
          to: "report_20",
        },
        {
          hasToBeRemoved: (user) => {
            return user.entityRoleId == 1 || user.systemRoleId != 2;
          },
          label: "Billing Report",
          to: "report_22",
        },
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId != 2;
          },
          label: "Rate History",
          to: "report_24",
        },
        {
          hasToBeRemoved: (user) => {
            return user.entityRoleId == 1 || user.systemRoleId != 2;
          },
          label: "Invoice Status Report",
          to: "report_23",
        },
        {
          hasToBeRemoved: (user) => {
            return user.entityRoleId == 1 || user.systemRoleId != 2;
          },
          label: "Search Report",
          to: "report_21",
        },
        {
          label: "Activity Details",
          to: "report_1",
        },
        {
          label: "Activity by Category",
          to: "report_2",
        },
        {
          label: "Activity Efficiency",
          to: "report_3",
        },
        {
          hasToBeRemoved: (user) => {
            return user.highestRoleId !== 3
              ? user.systemRoleId !== 2
                ? true
                : false
              : false;
          },
          label: "Law Firm Rate List",
          to: "report_25",
        },
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
          label: "Payment Report",
          to: "report_26",
        },
        {
          hasToBeRemoved: (user) => {
            return user.entityRoleId == 2;
          },
          label: "Flagged Activities",
          to: "report_18",
        },
        {
          hasToBeRemoved: (user) => {
            return user.entityRoleId == 1;
          },
          label: "My Activities",
          to: "report_12",
        },
        {
          label: "Biller Performance",
          to: "report_16",
        },
        {
          label: "Biller Hours Summary",
          to: "report_19",
        },
        {
          label: "Daily Breakdown",
          to: "report_15",
        },
        {
          label: "Matter Exception",
          to: "report_4",
        },
        {
          label: "Spend by Activity",
          to: "report_14",
        },
        {
          label: "Spend by Biller",
          to: "report_5",
        },
        {
          label: "Spend to Budget",
          to: "report_6",
        },
        {
          hasToBeRemoved: (user) => {
            return user.entityRoleId == 2;
          },
          label: "Spend by Matter",
          to: "report_7",
        },
        {
          hasToBeRemoved: (user) => {
            return user.entityRoleId !== 1;
          },
          label: "Spend by Law Firm",
          to: "report_11",
        },
        {
          hasToBeRemoved: (user) => {
            return user.highestRole < 3 && user.entityRoleId !== 2;
          },
          label: "Spend by Client",
          to: "report_11",
        },
        {
          label: "Spend by Matter Combined",
          to: "report_8",
        },
        {
          label: "Suspended Activity",
          to: "report_9",
        },
        {
          label: "PCLaw Export",
          to: "report_17",
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2 || user.entityRoleID == 1;
          },
        },
        {
          label: "Papercut Import",
          to: "report_27",
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
        },
      ],
    },
    {
      label: "Administration",
      content: [
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
          getLabelText: (user) => {
            return user.entityRoleId == 1 ? "Law Firms" : "Clients";
          },
          label: "Partnerships",
          to: "#/partnership",
        },
        {
          hasToBeRemoved: (user) => {
            return !user.canManageMatters && user.systemRoleId !== 2;
          },
          label: "Matters",
          to: "#/matter",
        },
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
          label: "Upload Expenses",
          to: "uploadExpenses",
        },
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
          label: "Expenses",
          to: "#/expenses",
        },
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
          label: "Payments",
          to: "#/payments",
        },
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
          label: "Users",
          to: "#/admin/users",
        },
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
          label: "Admin Notices",
          to: "#/admin/notice",
        },
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
          label: "Copy Activities",
          to: "copyactivities",
        },
        {
          hasToBeRemoved: (user) => {
            return (
              user.systemRoleId !== 2 ||
              user.entityRoleId == 1 ||
              (user.entityRoleId == 2 && user.systemRoleId !== 2)
            );
          },
          label: "Update Rates",
          to: "updaterates",
        },
        {
          label: "Profile",
          content: [
            {
              label: "Update Profile",
              to: "#/profile",
            },
            {
              label: "Set Alert Preferences",
              to: "#/alerts",
            },
            {
              label: "Change Password",
              to: "#/changePassword",
            },
          ],
        },
        {
          hasToBeRemoved: (user) => {
            return user.systemRoleId !== 2;
          },
          label: "Advanced",
          content: [
            {
              hasToBeRemoved: (user) => {
                return user.systemRoleId !== 2;
              },
              label: "Company Structure",
              to: "#/admin/managecompanies",
            },
            {
              hasToBeRemoved: (user) => {
                return user.systemRoleId !== 2;
              },
              label: "Category Packages",
              to: "#/admin/categorypackages",
            },
            {
              hasToBeRemoved: (user) => {
                return (
                  user.systemRoleId !== 2 ||
                  (user.parentId !== 0 && user.systemRoleId == 2)
                );
              },
              label: "Lists",
              to: "#/admin/managelists",
            },
          ],
        },
      ],
    },
    {
      label: "Logout",
      to: "#/logout",
    },
    {
      label: "Help",
      to: "#/help",
    },
  ];
  let ret = [];
  var x;
  nav.forEach((element) => {
    if (element.hasToBeRemoved == undefined || !element.hasToBeRemoved(user)) {
      if (element.hasToBeRemoved !== undefined)
        x = element.hasToBeRemoved(user);
      element.label =
        element.getLabelText !== undefined
          ? element.getLabelText(user)
          : element.label;
      if (element.content !== undefined && element.content.length > 0) {
        let newContent = [];
        element.content.forEach((el) => {
          if (el.hasToBeRemoved == undefined || !el.hasToBeRemoved(user)) {
            if (el.hasToBeRemoved !== undefined) x = el.hasToBeRemoved(user);
            el.label =
              el.getLabelText !== undefined ? el.getLabelText(user) : el.label;
            if (el.content !== undefined && el.content.length > 0) {
              let newContent2 = [];
              el.content.forEach((e) => {
                if (e.hasToBeRemoved == undefined || !e.hasToBeRemoved(user)) {
                  if (e.hasToBeRemoved !== undefined)
                    x = e.hasToBeRemoved(user);
                  e.label =
                    e.getLabelText !== undefined
                      ? e.getLabelText(user)
                      : e.label;
                  newContent2.push(e);
                }
              });
              el.content = newContent2;
            }
            newContent.push(el);
          }
        });
        element.content = newContent;
      }
      ret.push(element);
    }
  });

  if (
    user.systemRoleId !== 2 &&
    (user.highestRole == 2 || (user.highestRole == 0 && user.entityRoleId == 2))
  ) {
    let reports = ret.find((x) => x.label == "Reports");
    reports.content = [
      {
        label: "My Activities",
        to: "report_12",
      },
      {
        label: "Suspended Activities",
        to: "report_13",
      },
    ];
  }

  return ret;
}
