import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
} from "reactstrap";
import { api, userService } from "../../_services";
import DatePicker from "react-datepicker";

import moment from "moment";
import { currencyFormatter } from "../../_services/currencyFormatter";
import Loader from "react-loaders";
import download from "downloadjs";
import swal from "sweetalert";
import { TextareaAutosize } from "@material-ui/core";
import { DateTimeHelper } from "../../_helpers/dateTimeHelper";

const ReportModal = ({ toggleModal, showModal, selectedReportId }) => {
  let currentUser = userService.getLoggedInUser();
  let [isSubmitting, setIsSubmitting] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [selectedCurrencyId, setSelectedCurrencyId] = useState(
    currentUser.currencyId
  );
  let [startDate, setStartDate] = useState(new Date());
  let [endDate, setEndDate] = useState(new Date());
  let [selectedReportOption, setSelectedReportOption] = useState(0);
  let [currencyList, setCurrencyList] = useState([]);
  let [partnershipList, setPartnershipList] = useState([]);
  let [masterMatterList, setMasterMatterList] = useState([]);
  let [childMatterList, setChildMatterList] = useState([]);
  let [matterList, setMatterList] = useState([]);
  let [allPartnersSelected, setAllPartnersSelected] = useState(false);
  let [allMattersSelected, setAllMattersSelected] = useState(false);
  let [selectedDateRange, setSelectedDateRange] = useState(0);
  let [selectedExportType, setExportType] = useState("PDF");
  let [selectedOrder, setSelectedOrder] = useState("");
  let [orderColumns, setOrderColumns] = useState([]);
  let [password, setPassword] = useState("");
  let [billerMatterList, setBillerMatterList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermInvalid, setSearchTermInvalid] = useState(false);
  const [billerList, setBillerList] = useState([]);
  const exportFormats = ["PDF", "XLS", "CSV"];
  const reportTypes = [
    {
      name: "Activity Details", //by Duration',
      id: 1,
      selectMatters: true,
    },
    {
      name: "Activity by Category", //Detail',
      id: 2,
      selectMatters: true,
    },
    {
      name: "Activity Efficiency",
      id: 3,
      selectMatters: false,
    },
    {
      name: "Flagged Activities", //by Duration',
      id: 18,
      selectMatters: true,
    },
    {
      name: "Matter Exception",
      id: 4,
      selectMatters: true,
    },
    {
      name: "Biller Performance",
      id: 16,
      selectMatters: true,
    },
    {
      name: "Biller Hours Summary",
      id: 19,
      selectMatters: false,
    },
    {
      name: "Spend by Biller",
      id: 5,
      selectMatters: true,
    },
    {
      name: "Spend to Budget",
      id: 6,
      selectMatters: false,
    },
    {
      name: "Spend by Matter",
      id: 7,
      selectMatters: true,
    },
    {
      name: "Spend by Matter Combined",
      id: 8,
      selectMatters: true,
    },
    {
      name: "Suspended Activity",
      id: 9,
      selectMatters: true,
    },
    {
      name: "Revision",
      id: 10,
      selectMatters: true,
    },
    {
      name: `Spend by ${currentUser.entityRoleId == 1 ? "Law Firm" : "Client"}`,
      id: 11,
      selectMatters: false,
    },
    {
      name: "Spend by Activity",
      id: 14,
      selectMatters: true,
    },
    {
      name: "My Activities",
      id: 12,
      selectMatters: true,
      hasPassword: true,
    },
    {
      name: "Suspended Activities",
      id: 13,
      selectMatters: true,
    },
    {
      name: "Daily Breakdown",
      id: 15,
      selectMatters: true,
    },
    {
      name: "PCLaw Export",
      id: 17,
      selectMatters: true,
    },
    {
      name: "Accounts Receivable",
      id: 20,
      selectMatters: false,
    },
    {
      name: "Search Report",
      id: 21,
      selectMatters: false,
    },
    {
      name: "Billing Report",
      id: 22,
      selectMatters: false,
    },
    {
      name: "Invoice Status Report",
      id: 23,
      selectMatters: false,
    },
    {
      name: "Rate History",
      id: 24,
      selectMatters: true,
      canSelectAll: false,
    },
    {
      name: "Law Firm Rate List",
      id: 25,
      selectMatters: false,
      canSelectAll: false,
    },
    {
      name: "Payment Report",
      id: 26,
      selectMatters: false,
      canSelectAll: false,
    },
    {
      name: "Papercut Import",
      id: 27,
      selectMatters: false,
      canSelectAll: false,
    },
  ];

  useEffect(() => {
    if (showModal) {
      setSearchTerm("");
      setMatterList([]);
      api.getCurrencyList().then((list) => {
        setCurrencyList(list);
      });
      api
        .getPartnershipListByEntityId(
          currentUser.entityId,
          currentUser.userId,
          true
        )
        .then((data) => {
          setPartnershipList(
            data.map((x) => {
              x.isSelected = false;
              return x;
            })
          );
        });
      api
        .getMasterMatterListByUserId(currentUser.userId)
        .then((masterMatters) => {
          setMasterMatterList(
            masterMatters.map((x) => {
              x.isSelected = false;
              return x;
            })
          );
        });
      initOrderColumns();
      setAllPartnersSelected(false);
      setAllMattersSelected(false);
      if (selectedReportId == 20) {
        setSelectedDateRange(8);
      } else {
        setSelectedDateRange(0);
      }
      if (selectedReportId == 24) {
        api.getBillerListByEntityId(currentUser.entityId).then((b) => {
          setBillerList(b);
        });
      }

      if (currentUser.entityRoleId == 2) {
        api
          .getBillerMatterListByEntityIdIncludeTimes(
            currentUser.entityId,
            -1,
            currentUser.currencyId,
            new Date(),
            -1,
            -1,
            -1
          )
          .then((data) => {
            setBillerMatterList(data);
          });
      }
    }
    setIsSubmitting(false);
  }, [showModal]);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  });

  const initOrderColumns = () => {
    let oc = [];
    switch (parseInt(selectedReportId)) {
      case 1:
      case 18:
        oc = ["Activity", "Date", "Spend", "Duration", "Biller"];
        break;
      case 2:
        oc = ["Date", "Category", "Spend", "Duration", "Biller"];
        break;
      case 3:
        oc = ["Activity", "Lawfirm"];
        break;
      case 4:
        oc = ["Biller", "Lawfirm", "Amount"];
        break;
      case 5:
        oc = ["Biller", "Spend"];
        break;
      case 6:
        oc = ["Firm", "Matter", "Amount", "Remaining"];
        break;
      case 7:
        oc = ["Matter", "Spend", "Firm"];
        break;
      case 8:
        oc = ["Matter", "Spend"];
        break;
      case 9:
        oc = ["Date", "Spend", "Biller"];
        break;
      case 10:
        oc = ["Biller", "Date"];
        break;
      case 11:
      case 15:
      case 19:
      case 20:
      case 21:
      case 23:
      case 25:
      case 26:
        oc = [];
        break;
      case 12:
        oc = ["Date", "Category", "Spend", "Duration", "Biller"];
        break;
      case 13:
        oc = ["Date", "Spend", "Biller"];
        break;
      case 14:
        oc = ["Spend", "Duration", "Activity"];
        break;
      case 16:
        oc = ["Biller", "RTA", "RTD"];
    }
    setSelectedOrder(oc.length > 0 ? oc[0] : "");
    setOrderColumns(oc);
  };

  const getMatterList = (partnershipIdList) => {
    setIsLoading(true);
    let selectedRpt = reportTypes.find((x) => x.id == selectedReportId);
    if (
      (partnershipIdList.length > 0 || masterMatterList.length > 0) &&
      selectedRpt.selectMatters
    ) {
      let partnershipIds = partnershipIdList.join(",");

      if (currentUser.entityRoleId == 2) {
        if (selectedRpt.id == 12) {
          api
            .getMatterListByPartnershipIds(
              currentUser.userId,
              partnershipIds,
              currentUser.entityId,
              true
            )
            .then((list) => {
              setMatterList(list);
              setIsLoading(false);
            });
        } else {
          if (currentUser.systemRoleId == 2) {
            api
              .getMatterListByPartnershipIds(
                currentUser.userId,
                partnershipIds,
                currentUser.entityId,
                true
              )
              .then((list) => {
                setMatterList(list);
                setIsLoading(false);
              });
          } else if (currentUser.highestRole == 3) {
            api
              .getMatterListByPartnershipIds(
                currentUser.userId,
                partnershipIds,
                currentUser.entityId,
                true
              )
              .then((list) => {
                let billerOnlyMats = billerMatterList.filter(
                  (x) => partnershipIds.indexOf(x.partnershipId) > -1
                );
                list = list.filter((x) => {
                  if (
                    billerOnlyMats.find((b) => b.matterId == x.matterId) !==
                    undefined
                  ) {
                    return false;
                  }
                  return true;
                });
                setMatterList(list);
                setIsLoading(false);
              });
          } else {
            let mats = billerMatterList.filter(
              (x) => partnershipIds.indexOf(x.partnershipId) > -1
            );
            setMatterList(mats);
            setIsLoading(false);
          }
        }
      } else {
        api
          .getMatterListByPartnershipIds(
            currentUser.userId,
            partnershipIds,
            currentUser.entityId,
            true
          )
          .then((list) => {
            let mattersFromMaster = [];
            if (currentUser.entityRoleId == 1) {
              matterList.filter((x) => x.isSetFromMasterMatter == true);
            }

            setMatterList([...list, ...mattersFromMaster]);
            setIsLoading(false);
          });
      }
    } else {
      setMatterList([]);
      setIsLoading(false);
    }
  };

  const downloadReport = () => {
    setIsSubmitting(true);

    if (selectedReportId == 24) {
      let part = partnershipList.find((x) => x.isSelected);
      let mat = matterList.find((x) => x.isSelected);
      let usr = billerList.find((x) => x.isSelected);
      if (part == undefined && mat == undefined && usr == undefined) {
        setIsSubmitting(false);
        swal(`Please select at least one Client or Matter or User.`, {
          buttons: {
            ok: {
              text: "Ok",
              value: "ok",
              className: "btn-success",
            },
          },
          icon: "warning",
        });
      } else {
        let partId = part == undefined ? 0 : part.partnershipId;
        let matterId = mat == undefined ? 0 : mat.matterId;
        let billerId = usr == undefined ? 0 : usr.userId;
        let dates = getDates(selectedDateRange);
        api
          .getRateHistoryReport(
            moment(dates.startDate).format("YYYY-MM-DDTHH:mm:ss"),
            moment(dates.endDate).format("YYYY-MM-DDTHH:mm:ss"),
            selectedCurrencyId,
            selectedExportType,
            partId,
            matterId,
            billerId
          )
          .then(handleReportCallback);
      }
    } else if (selectedReportId == 21) {
      if (searchTerm.length < 4) {
        setSearchTermInvalid(true);
        return;
      }
      api
        .getSearchReport(searchTerm, selectedExportType)
        .then(handleReportCallback);
    } else if (selectedReportId == 25) {
      let date = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
      api
        .getReport(
          selectedReportId,
          date,
          null,
          selectedCurrencyId,
          [],
          [],
          selectedExportType,
          selectedOrder,
          "",
          selectedReportOption
        )
        .then(handleReportCallback);
    } else if (selectedReportId == 26) {
      let dates = getDates(selectedDateRange);
      let startOfDay = moment(dates.startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");

      let endOfDay = moment(dates.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      // let startOfDay = DateTimeHelper.getUtcDateTimeStringFromUser(
      //   moment(dates.startDate).startOf("day"),
      //   "YYYY-MM-DDTHH:mm:ss"
      // );
      // let endOfDay = DateTimeHelper.getUtcDateTimeStringFromUser(
      //   moment(dates.endDate).endOf("day"),
      //   "YYYY-MM-DDTHH:mm:ss"
      // );
      // console.log(startOfDay);
      // console.log(endOfDay);
      api
        .getPaymentReport(
          currentUser.entityId,
          startOfDay,
          endOfDay,
          selectedExportType
        )
        .then(handleReportCallback);
    } else if (selectedReportId == 27) {
      api
        .getPapercutImportReport(currentUser.userId)
        .then(handleReportCallback);
    } else {
      let lawfirmIds = partnershipList
        .filter((x) => x.isSelected)
        .map((x) => x.partnershipId)
        .join(",");
      let matterIds =
        matterList.length > 0
          ? matterList
              .filter((x) => x.isSelected)
              .map((x) => x.matterId)
              .join(",")
          : null;
      let dates = getDates(selectedDateRange);
      var report = reportTypes.find((x) => x.id == selectedReportId);
      if (report.selectMatters && (matterIds == null || matterIds == "")) {
        setIsSubmitting(false);
        swal(`Please select at least one matter.`, {
          buttons: {
            ok: {
              text: "Ok",
              value: "ok",
              className: "btn-success",
            },
          },
          icon: "warning",
        });
      } else {
        api
          .getReport(
            selectedReportId,
            moment(dates.startDate).format("YYYY-MM-DDTHH:mm:ss"),
            moment(dates.endDate).format("YYYY-MM-DDTHH:mm:ss"),
            selectedCurrencyId,
            lawfirmIds,
            matterIds,
            selectedExportType,
            selectedOrder,
            password,
            selectedReportOption
          )
          .then(handleReportCallback);
      }
    }
  };

  const handleReportCallback = (blob) => {
    setIsSubmitting(false);
    download(
      blob,
      selectedReportId == 17
        ? "ExternalBillingSystem.csv"
        : selectedReportId == 27
        ? "PapercutImport.csv"
        : `${reportTypes
            .find((x) => x.id == selectedReportId)
            .name.replace(/\s+/g, "")}.${selectedExportType.toLowerCase()}`
    );
  };

  const getDates = (dateRange) => {
    let sd, ed;
    let userNow = moment.tz("UTC").tz(currentUser.timezoneIanaName);
    switch (parseInt(dateRange)) {
      case 0: //from today
        sd = userNow.format("YYYY-MM-DD");
        ed = userNow.format("YYYY-MM-DD");
        break;
      case 1: //from yesterday
        sd = userNow.clone().subtract(1, "days").format("YYYY-MM-DD");
        ed = userNow.format("YYYY-MM-DD");
        break;
      case 2: //from last 7 days
        sd = userNow.clone().subtract(7, "days").format("YYYY-MM-DD");
        ed = userNow.format("YYYY-MM-DD");
        break;
      case 3: //from last 30 days
        sd = userNow.clone().subtract(30, "days").format("YYYY-MM-DD");
        ed = userNow.format("YYYY-MM-DD");
        break;
      case 4: //this month
        sd = userNow.clone().startOf("month").format("YYYY-MM-DD");
        ed = userNow.clone().endOf("month").format("YYYY-MM-DD");
        break;
      case 5: //last month
        sd = userNow
          .clone()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        ed = userNow
          .clone()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case 6: //current quarter
        sd = moment()
          .quarter(moment().quarter())
          .startOf("quarter")
          .format("YYYY-MM-DD");
        ed = moment()
          .quarter(moment().quarter())
          .endOf("quarter")
          .format("YYYY-MM-DD");
        break;
      case 7: //current year
        sd = moment().startOf("year").format("YYYY-MM-DD");
        ed = userNow.format("YYYY-MM-DD");
        break;
      case 8: //custom
        sd = moment(startDate).format("YYYY-MM-DD");
        ed = moment(endDate).format("YYYY-MM-DD");
        break;
    }
    return { startDate: sd, endDate: ed };
  };

  const changePartnerSelection = (e) => {
    if (!isLoading) {
      if (selectedReportId == 24) {
        setPartnershipList(
          partnershipList.map((p) => {
            if (p.partnershipId == e.partnershipId) {
              p.isSelected = !p.isSelected;
            } else {
              p.isSelected = false;
            }
            return p;
          })
        );
        getMatterList([e.partnershipId]);
      } else {
        let pl = [...partnershipList];
        let partner = pl.find((x) => x.partnershipId == e.partnershipId);
        partner.isSelected = !partner.isSelected;
        getMatterList(
          pl.filter((x) => x.isSelected).map((x) => x.partnershipId)
        );
        setPartnershipList(pl);
      }
    }
  };

  const changeMatterSelection = (e) => {
    if (selectedReportId == 24) {
      setMatterList((prev) =>
        prev.map((m) => {
          if (m.matterId == e.matterId) {
            m.isSelected = !m.isSelected;
          } else {
            m.isSelected = false;
          }

          return m;
        })
      );
    } else {
      let ml = [...matterList];
      let matter = ml.find((x) => x.matterId == e.matterId);
      matter.isSelected = !matter.isSelected;
      setMatterList(ml);
    }
  };

  const changeBillerSelection = (e) => {
    setBillerList(
      billerList.map((b) => {
        if (b.userId == e.userId) {
          b.isSelected = !b.isSelected;
        } else {
          b.isSelected = false;
        }
        return b;
      })
    );
  };

  const changeMasterMatterSelection = (mat) => {
    if (!isLoading) {
      let masterList = [...masterMatterList];
      let masterMatter = masterList.find((x) => x.matterId == mat.matterId);
      masterMatter.isSelected = !masterMatter.isSelected;
      setMasterMatterList(masterList);
      getChildMatterList(masterList.filter((x) => x.isSelected));
    }
  };

  const getChildMatterList = (list) => {
    let masterMatterIdList = list.map((x) => x.matterId);
    let existingMatters = [...matterList];
    existingMatters = existingMatters.filter(
      (x) => x.isSetFromMasterMatter !== true
    );
    if (
      masterMatterIdList.length > 0 &&
      reportTypes.find((x) => x.id == selectedReportId).selectMatters
    ) {
      setIsLoading(true);
      let requests = [];
      masterMatterIdList.forEach((id) => {
        requests.push(api.getChildMatterListByMatterId(id));
      });

      if (requests.length > 0) {
        Promise.all(requests).then((childMatterArrays) => {
          let matters = [];
          childMatterArrays.forEach((arr) => {
            matters = [...matters, ...arr];
          });
          setChildMatterList(
            matters.map((x) => {
              x.isSetFromMasterMatter = true;
              return x;
            })
          );

          if (matters.length > 0) {
            matters.forEach((m) => {
              if (
                existingMatters.find(
                  (x) => x.matterId == m.matterId && x.isSetFromMasterMatter
                ) == undefined
              ) {
                m.isSetFromMasterMatter = true;
                existingMatters.push(m);
              }
            });

            let lst = JSON.parse(
              JSON.stringify(
                list.map((x) => {
                  x.isSetFromMasterMatter = true;
                  return x;
                })
              )
            );
            existingMatters = [...lst, ...existingMatters];

            setMatterList(existingMatters);
          }
          setIsLoading(false);
        });
      } else {
        setMatterList(existingMatters);
        setIsLoading(false);
      }
    } else {
      setMatterList(existingMatters);
    }
  };

  return (
    <Modal
      size="md"
      isOpen={showModal}
      toggle={() => toggleModal()}
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleModal()}>
        {
          reportTypes.find((x) => parseInt(x.id) == parseInt(selectedReportId))
            .name
        }
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row>
                  {selectedReportId !== 17 && selectedReportId !== 21 && (
                    <>
                      {orderColumns.length > 0 && (
                        <div className="form-group col-md-12">
                          <label>Order By</label>
                          <FormGroup>
                            {orderColumns.map((x, i) => {
                              return (
                                <CustomInput
                                  id={`o-${i}`}
                                  key={`oz-${i}`}
                                  type="radio"
                                  value={x}
                                  label={
                                    x == "RTA"
                                      ? "% Real Time Ascending"
                                      : x == "RTD"
                                      ? "% Real Time Descending"
                                      : x
                                  }
                                  checked={x === selectedOrder}
                                  onChange={(e) =>
                                    setSelectedOrder(e.target.value)
                                  }
                                />
                              );
                            })}
                          </FormGroup>
                        </div>
                      )}
                      <div className="form-group col-md-12">
                        <label>Download As</label>
                        <FormGroup>
                          {exportFormats.map((x, i) => {
                            return (
                              <CustomInput
                                id={`ex-${i}`}
                                key={`e-${i}`}
                                type="radio"
                                value={x}
                                label={x}
                                checked={x === selectedExportType}
                                onChange={(e) => setExportType(e.target.value)}
                              />
                            );
                          })}
                        </FormGroup>
                      </div>
                      {selectedReportId !== 3 &&
                        selectedReportId !== 4 &&
                        selectedReportId !== 26 &&
                        selectedReportId != 27 && (
                          <div className="form-group col-md-12">
                            <label htmlFor="currency">Currency</label>
                            <CustomInput
                              type="select"
                              id="currencySelect"
                              name="currencySelect"
                              className="form-control"
                              onChange={(e) => {
                                setSelectedCurrencyId(e.target.value);
                              }}
                              value={selectedCurrencyId}
                            >
                              {currencyList.map((currency) => {
                                return (
                                  <option
                                    key={currency.currencyId}
                                    value={currency.currencyId}
                                  >
                                    {currency.displayValue}
                                  </option>
                                );
                              })}
                            </CustomInput>
                          </div>
                        )}
                    </>
                  )}
                  {selectedReportId == 16 && (
                    <div className="form-group col-md-12">
                      <label htmlFor="rpt">Report option</label>
                      <CustomInput
                        type="select"
                        id="rp"
                        name="rpto"
                        className="form-control"
                        onChange={(e) => {
                          setSelectedReportOption(e.target.value);
                        }}
                        value={selectedReportOption}
                      >
                        <option key={0} value={0}>
                          Summarize by Biller
                        </option>
                        <option key={1} value={1}>
                          Summarize by Matter and Biller
                        </option>
                      </CustomInput>
                    </div>
                  )}
                  {selectedReportId !== 21 &&
                    selectedReportId !== 25 &&
                    selectedReportId !== 26 &&
                    selectedReportId !== 27 && (
                      <div className="form-group col-md-12">
                        <Card style={{ width: "100%" }}>
                          <CardBody
                            style={{ maxHeight: "200px", overflowY: "auto" }}
                          >
                            <table
                              className="table-responsive"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th width="10%">Select</th>
                                  <th width="90%">
                                    {currentUser.entityRoleId == 1
                                      ? "Law Firm"
                                      : "Client"}{" "}
                                    {selectedReportId !== 24 && "(s)"}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {reportTypes.find(
                                  (x) => x.id == selectedReportId
                                ).canSelectAll !== false && (
                                  <tr>
                                    <td>
                                      <CustomInput
                                        type="checkbox"
                                        id={"report-selectAll0"}
                                        style={{
                                          marginLeft: "3px",
                                          cursor: "pointer",
                                        }}
                                        value={allPartnersSelected}
                                        checked={allPartnersSelected}
                                        onChange={() => {
                                          let pl = [...partnershipList];
                                          setPartnershipList(
                                            pl.map((x) => {
                                              x.isSelected =
                                                !allPartnersSelected;
                                              return x;
                                            })
                                          );
                                          getMatterList(
                                            pl
                                              .filter((x) => x.isSelected)
                                              .map((x) => x.partnershipId)
                                          );
                                          setAllPartnersSelected(
                                            !allPartnersSelected
                                          );
                                        }}
                                      />
                                    </td>
                                    <td
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        let pl = [...partnershipList];
                                        setPartnershipList(
                                          pl.map((x) => {
                                            x.isSelected = !allPartnersSelected;
                                            return x;
                                          })
                                        );
                                        getMatterList(
                                          pl
                                            .filter((x) => x.isSelected)
                                            .map((x) => x.partnershipId)
                                        );
                                        setAllPartnersSelected(
                                          !allPartnersSelected
                                        );
                                      }}
                                    >
                                      Select all
                                    </td>
                                  </tr>
                                )}
                                {partnershipList.map((e) => {
                                  return (
                                    <tr
                                      key={
                                        "id-report-partnership-" +
                                        e.partnershipId
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>
                                        <CustomInput
                                          type="checkbox"
                                          id={
                                            "report-partnership-" +
                                            e.partnershipId
                                          }
                                          style={{ cursor: "pointer" }}
                                          value={e.isSelected}
                                          checked={e.isSelected}
                                          onChange={() =>
                                            changePartnerSelection(e)
                                          }
                                        />
                                      </td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          changePartnerSelection(e)
                                        }
                                      >
                                        {e.partnershipName}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {currentUser.entityRoleId == 1 &&
                                  masterMatterList.map((e) => {
                                    return (
                                      <tr
                                        key={"master-matter-" + e.matterId}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <td>
                                          <CustomInput
                                            type="checkbox"
                                            id={"report-mm-" + e.matterId}
                                            style={{ cursor: "pointer" }}
                                            value={e.isSelected}
                                            checked={e.isSelected}
                                            onChange={() => {
                                              changeMasterMatterSelection(e);
                                            }}
                                          />
                                        </td>
                                        <td
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            changeMasterMatterSelection(e);
                                          }}
                                        >
                                          [Master Matter] {e.matterName}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </CardBody>
                        </Card>
                      </div>
                    )}
                  {reportTypes.find((x) => x.id == selectedReportId)
                    .selectMatters && (
                    <div className="form-group col-md-12">
                      <Card style={{ width: "100%" }}>
                        <CardBody
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          <table
                            className="table-responsive"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th width="10%">Select</th>
                                <th width="90%">
                                  Matter {selectedReportId !== 24 && "(s)"}{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportTypes.find((x) => x.id == selectedReportId)
                                .canSelectAll !== false && (
                                <tr>
                                  <td>
                                    <CustomInput
                                      type="checkbox"
                                      id={"report-mselectAll0"}
                                      style={{
                                        marginLeft: "3px",
                                        cursor: "pointer",
                                      }}
                                      value={allMattersSelected}
                                      checked={allMattersSelected}
                                      onChange={() => {
                                        let ml = [...matterList];
                                        setMatterList(
                                          ml.map((x) => {
                                            x.isSelected = !allMattersSelected;
                                            return x;
                                          })
                                        );
                                        setAllMattersSelected(
                                          !allMattersSelected
                                        );
                                      }}
                                    />
                                  </td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      let ml = [...matterList];
                                      setMatterList(
                                        ml.map((x) => {
                                          x.isSelected = !allMattersSelected;
                                          return x;
                                        })
                                      );
                                      setAllMattersSelected(
                                        !allMattersSelected
                                      );
                                    }}
                                  >
                                    Select all
                                  </td>
                                </tr>
                              )}
                              {matterList
                                .filter((value, index, self) => {
                                  return (
                                    self.findIndex(
                                      (v) => v.matterId === value.matterId
                                    ) === index
                                  );
                                })
                                .map((e) => {
                                  return (
                                    <tr
                                      key={"id-rort-matter-" + e.matterId}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>
                                        <CustomInput
                                          type="checkbox"
                                          id={"rport-matter-" + e.matterId}
                                          style={{ cursor: "pointer" }}
                                          value={e.isSelected}
                                          checked={e.isSelected}
                                          onChange={() =>
                                            changeMatterSelection(e)
                                          }
                                        />
                                      </td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() => changeMatterSelection(e)}
                                      >
                                        {currentUser.entityRoleId == 2
                                          ? e.matterName
                                          : e.masterMatterId > 0 &&
                                            masterMatterList.find(
                                              (x) =>
                                                x.matterId == e.masterMatterId
                                            ) !== undefined
                                          ? `${
                                              masterMatterList.find(
                                                (x) =>
                                                  x.matterId == e.masterMatterId
                                              ).matterName
                                            } - ${e.matterName}`
                                          : e.matterName}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                    </div>
                  )}

                  {selectedReportId == 24 && (
                    <div className="form-group col-md-12">
                      <Card style={{ width: "100%" }}>
                        <CardBody
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          <table
                            className="table-responsive"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th width="10%">Select</th>
                                <th width="90%">User </th>
                              </tr>
                            </thead>
                            <tbody>
                              {billerList.map((e, idx) => {
                                return (
                                  <tr key={idx} style={{ cursor: "pointer" }}>
                                    <td>
                                      <CustomInput
                                        type="checkbox"
                                        style={{ cursor: "pointer" }}
                                        value={e.isSelected}
                                        checked={e.isSelected}
                                        onChange={() =>
                                          changeBillerSelection(e)
                                        }
                                      />
                                    </td>
                                    <td
                                      style={{ cursor: "pointer" }}
                                      onClick={() => changeBillerSelection(e)}
                                    >
                                      {e.name}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                    </div>
                  )}

                  {selectedReportId == 25 && (
                    <div className="form-group col-md-6">
                      <label htmlFor="Date">Date</label>
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Date..."
                      />
                    </div>
                  )}

                  {selectedReportId !== 20 &&
                    selectedReportId !== 21 &&
                    selectedReportId !== 25 &&
                    selectedReportId !== 27 && (
                      <div className="form-group col-md-12">
                        <label htmlFor="dateRange">Date Range</label>
                        <CustomInput
                          type="select"
                          id="dateRange"
                          name="dateRange"
                          value={selectedDateRange}
                          onChange={(e) => setSelectedDateRange(e.target.value)}
                        >
                          <option key={`report-dr-${0}`} value={0}>
                            Today
                          </option>
                          <option key={`report-dr-${1}`} value={1}>
                            Yesterday
                          </option>
                          <option key={`report-dr-${2}`} value={2}>
                            Last 7 Days
                          </option>
                          <option key={`report-dr-${3}`} value={3}>
                            Last 30 Days
                          </option>
                          <option key={`report-dr-${4}`} value={4}>
                            This Month
                          </option>
                          <option key={`report-dr-${5}`} value={5}>
                            Last Month
                          </option>
                          <option key={`report-dr-${6}`} value={6}>
                            Quarter to Date
                          </option>
                          <option key={`report-dr-${7}`} value={7}>
                            Year to Date
                          </option>
                          <option key={`report-dr-${8}`} value={8}>
                            Custom Range
                          </option>
                        </CustomInput>
                        {selectedDateRange == 8 && (
                          <Row>
                            <div className="form-group col-md-6">
                              <label htmlFor="startDate">Start Date</label>
                              <DatePicker
                                className="form-control"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                placeholderText="Start date..."
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="endDate">End Date</label>
                              <DatePicker
                                className="form-control"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                placeholderText="Start date..."
                              />
                            </div>
                          </Row>
                        )}
                      </div>
                    )}
                  {selectedReportId == 20 && (
                    <div className="form-group col-md-12">
                      <Row>
                        <div className="form-group col-md-6">
                          <label htmlFor="startDate">Start Date</label>
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Start date..."
                          />
                        </div>
                      </Row>
                    </div>
                  )}
                  {selectedReportId == 21 && (
                    <div className="form-group col-md-12">
                      <label htmlFor="searchTerm">Search Term</label>
                      <TextareaAutosize
                        className="form-control"
                        minRows={2}
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setSearchTermInvalid(false);
                        }}
                        placeholderText="Search..."
                      />
                      {searchTermInvalid && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Search term must be 4 or more letters
                        </div>
                      )}
                    </div>
                  )}
                  {reportTypes.find((x) => x.id == selectedReportId)
                    .hasPassword && (
                    <div className="form-group col-md-12">
                      <label>
                        Password (Required to decrypt activity notes. If not
                        provided, notes will not appear.)
                      </label>
                      <Input
                        type="password"
                        name="pass"
                        id="pass"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  )}

                  {selectedReportId == "16" && (
                    <div className="form-group col-md-12">
                      <p style={{ color: "red" }}>
                        This report can take a few minutes to run.
                      </p>
                    </div>
                  )}

                  <div className="form-group col-md-12">
                    <button
                      className="btn btn-primary"
                      disabled={isSubmitting}
                      onClick={() => downloadReport()}
                    >
                      Download
                    </button>
                    {isSubmitting && (
                      <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    )}
                    {/* <a href='#' onClick={e => { e.preventDefault(); downloadReport() }}>download</a> */}
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ReportModal;
